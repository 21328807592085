.@{ns}sidebar {
  box-shadow: @default-box-shadow;

  .@{ns}dropdown-item-active {
    .@{ns}dropdown-item-content {
      box-shadow: inset -2px 0px 0px 0px @dropdown-link-active-color;
    }
  }

  .@{ns}nav-item-active {
    .@{ns}nav-item-content {
      box-shadow: inset -2px 0px 0px 0px @dropdown-link-active-color;
    }
  }
}
