.@{ns}modal {
  max-width: 100%;
  margin: 30px auto;

  .@{ns}form-popover-container {
    position: relative;
  }

  .@{ns}popover {
    &.max-width {
      width: calc(100% + 20px);

      &.placement-top-start,
      &.placement-bottom-start {
        margin-left: -10px;
        .@{ns}popover-arrow {
          margin-left: 4px;
        }
      }

      &.placement-top-end,
      &.placement-bottom-end {
        margin-left: 10px;
        .@{ns}popover-arrow {
          right: 22px;
        }
      }
    }
  }

  &-body {
    margin-bottom: @modal-body-margin;
    padding-bottom: 0;
    word-break: break-word;
  }

  &-footer {
    .@{ns}btn-toolbar {
      text-align: right;
    }
  }

  &-content-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: rgba(#fefefe, 0.65);
    border-radius: 6px;
  }

  &.modal-confirm {
    text-align: center;

    .@{ns}modal-header {
      padding: 0;

      .@{ns}icon {
        font-size: 30px;
        border: 2px solid @border-primary-color;
        width: 70px;
        height: 70px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        color: @primary-color;
      }
    }

    .@{ns}modal-footer {
      text-align: center;
    }
  }
}
