.@{ns}dropdown {
  &-item {
    &-content {
      position: relative;
      transition: color 0.3s linear, background-color 0.3s linear,
        padding-left 0.3s !important;

      .@{ns}loader-wrapper {
        position: absolute;
        top: 10px;
        left: 10px;
      }

      .@{ns}progress {
        display: none;
      }
    }

    &-loading &-content {
      padding-left: 33px;
    }

    &-progressing &-content {
      padding-left: 33px;

      .@{ns}progress {
        display: inline-block;
        width: 16px;
        height: 16px;
        position: absolute;
        left: 10px;
        top: 10px;
      }
    }
  }
}
