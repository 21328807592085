.@{ns}loader {
  &-wrapper {
    z-index: 100;
  }

  &-center & {
    height: 100%;
  }

  &-spin {
    &:before,
    &:after {
      border-width: 1px;
    }

    &:before {
      border-color: rgba(#888, 0.2);
    }

    &:after {
      border-color: #888 transparent transparent;
    }
  }
}
