.@{ns}nav {
  &-item {
    &.sidebar-collapsible-toggle {
      width: @sidenav-default-width;
      text-align: center;

      .@{ns}nav-item-content {
        font-size: 18px;
        line-height: 1em;
      }
    }

    .@{ns}loader-wrapper {
      vertical-align: text-bottom;
      margin: 0 7px 0 -5px;
    }

    &-panel {
      font-size: @font-size-small;
      color: rgba(@sidenav-default-font-color, 0.5);
      padding: 40px @sidenav-padding-horizonal @sidenav-padding-vertical
        @sidenav-padding-horizonal;
      // border-bottom: @panel-border;
      text-transform: uppercase;
    }
  }
}
