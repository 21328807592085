@import './node_modules/rsuite/lib/styles/themes/default/core';

@icon-font-path: '/fonts/rsuite-icon';

@import './rsuite-icon-font-builder/dist/iconfont-variables.less';
@import './rsuite-icon-font-builder/dist/iconfont.less';

@font-family-base: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
  'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;

@notify-title-font-size: @font-size-base;
@notify-title-font-color: @notify-description-color;
@divider-default-horizontal-margin: 12px;

@font-size-large: 16px;
@font-size-base: 14px;
@font-size-small: 12px;
@font-size-extra-small: 12px;

@font-size-h1: 32px;
@font-size-h2: 26px;
@font-size-h3: 22px;
@font-size-h4: 18px;
@font-size-h5: 16px;
@font-size-h6: 14px; // The same to font-size-base

@line-height-h1: 36px;
@line-height-h2: 30px;
@line-height-h3: 26px;
@line-height-h4: 24px;
@line-height-h5: 22px;
@line-height-h6: 20px;

:root {
  --color-list-border: @list-border-color;
  --color-list-item-border: ~'@{list-border-color}66';

  --color-state-success-text: @state-success-text;
  --color-state-success-bg: @state-success-bg;

  --color-state-info-text: @state-info-text;
  --color-state-info-bg: @state-info-bg;

  --color-state-warning-text: @state-warning-text;
  --color-state-warning-bg: @state-warning-bg;

  --color-state-error-text: @state-error-text;
  --color-state-error-bg: @state-error-bg;

  --color-text: @text-color;
  --color-text-light: ~'@{text-color}AA';
}

// this should represent the list of all used rsuite components
@import './node_modules/rsuite/lib/Animation/styles/themes/default';
@import './node_modules/rsuite/lib/Avatar/styles/themes/default';
@import './node_modules/rsuite/lib/Button/styles/themes/default';
@import './node_modules/rsuite/lib/ButtonToolbar/styles/themes/default';
@import './node_modules/rsuite/lib/Calendar/styles/themes/default';
@import './node_modules/rsuite/lib/Checkbox/styles/themes/default';
@import './node_modules/rsuite/lib/CheckboxGroup/styles/themes/default';
@import './node_modules/rsuite/lib/Container/styles/themes/default';
@import './node_modules/rsuite/lib/Content/styles/themes/default';
@import './node_modules/rsuite/lib/ControlLabel/styles/themes/default';
@import './node_modules/rsuite/lib/DatePicker/styles/themes/default';
@import './node_modules/rsuite/lib/Divider/styles/themes/default';
@import './node_modules/rsuite/lib/Drawer/styles/themes/default';
@import './node_modules/rsuite/lib/Dropdown/styles/themes/default';
@import './node_modules/rsuite/lib/ErrorMessage/styles/themes/default';
@import './node_modules/rsuite/lib/Form/styles/themes/default';
@import './node_modules/rsuite/lib/FormControl/styles/themes/default';
@import './node_modules/rsuite/lib/FormGroup/styles/themes/default';
@import './node_modules/rsuite/lib/Header/styles/themes/default';
@import './node_modules/rsuite/lib/Icon/styles/themes/default';
@import './node_modules/rsuite/lib/IconButton/styles/themes/default';
@import './node_modules/rsuite/lib/Input/styles/themes/default';
@import './node_modules/rsuite/lib/InputNumber/styles/themes/default';
@import './node_modules/rsuite/lib/InputGroup/styles/themes/default';
@import './node_modules/rsuite/lib/InputPicker/styles/themes/default';
@import './node_modules/rsuite/lib/List/styles/themes/default';
@import './node_modules/rsuite/lib/Loader/styles/themes/default';
@import './node_modules/rsuite/lib/Message/styles/themes/default';
@import './node_modules/rsuite/lib/Modal/styles/themes/default';
@import './node_modules/rsuite/lib/Nav/styles/themes/default';
@import './node_modules/rsuite/lib/Navbar/styles/themes/default';
@import './node_modules/rsuite/lib/Notification/styles/themes/default';
@import './node_modules/rsuite/lib/Panel/styles/themes/default';
@import './node_modules/rsuite/lib/Picker/styles/themes/default';
@import './node_modules/rsuite/lib/Popover/styles/themes/default';
@import './node_modules/rsuite/lib/SelectPicker/styles/themes/default';
@import './node_modules/rsuite/lib/Sidebar/styles/themes/default';
@import './node_modules/rsuite/lib/Sidenav/styles/themes/default';
@import './node_modules/rsuite/lib/Tag/styles/themes/default';

@import './Avatar';
@import './Button';
@import './Container';
@import './Content';
@import './Drawer';
@import './Dropdown';
@import './FlexboxGrid';
@import './Form';
@import './Icon';
@import './Input';
@import './Loader';
@import './Modal';
@import './Nav';
@import './Navbar';
@import './Notification';
@import './Popover';
@import './Progress';
@import './Sidebar';
@import './Sidenav';
