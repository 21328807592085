.@{ns}btn {
  transition: padding-left 0.3s;

  &-loading {
    padding-left: 33px;

    &.@{ns}btn-primary {
      color: @btn-primary-color !important;
    }

    .@{ns}btn-spin {
      &:after,
      &:before {
        margin: auto auto auto 9px;
        border-width: 1px;
      }
    }
  }

  .@{ns}progress {
    display: none;

    &-stroke,
    &-trail {
      stroke: @btn-primary-color !important;
      stroke-width: 6;
    }

    &-trail {
      opacity: 0.3;
    }
  }

  &-progressing {
    padding-left: 33px;

    .@{ns}progress {
      display: inline-block;
      width: 18px;
      height: 14px;
      position: absolute;
      left: 9px;
      top: 9px;
    }
  }

  &-inline {
    padding: 0;
    margin: 0;
    display: inline;
    line-height: inherit;
    vertical-align: inherit;
  }

  &-toolbar {
    &-right {
      text-align: right;
    }
  }

  &-toolbar & + & {
    margin-left: 10px;
    margin-bottom: 0;
  }
}
