.l-flex {
  display: flex;

  &--column {
    flex-direction: column;
  }

  &--row {
    flex-direction: row;
  }

  &--center-center {
    align-items: center;
    justify-content: center;
  }

  &__item {
    &--main {
      flex-grow: 1000;
      width: auto;
    }

    &--no-shrink {
      flex-shrink: 0;
    }

    &--overflow {
      max-height: 100%;
      overflow: auto;
    }
  }
}
