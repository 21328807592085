.content {
  max-width: 960px;
  margin: 0 auto;
  padding: 1em;

  h2 {
    margin-top: 1em;
    margin-bottom: 0.25em;
  }

  h3 {
    margin-top: 1em;
    margin-bottom: 0.25em;
  }
}
