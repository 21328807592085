.root {
  padding: 1em;
}

.blockquote {
  border-left: 3px solid #eee; //#3498ff;
  // color: #1a1a1a;
  font-family: Georgia, Times, 'Times New Roman', serif;
  font-size: 1.25em;
  // font-style: italic;
  // line-height: 1.8em;
  padding: 0 0 0 1em;
  position: relative;
  z-index: 0;
  margin: 1em 0;
}

.cite {
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  font-size: 0.75em;
  line-height: 1.8em;
  margin-top: 1em;
  // font-style: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.image {
  width: 100%;
  max-height: 200px;
  object-fit: contain;
  object-position: center;
  padding: 2px;
  margin-bottom: 1em;
}

.avatar {
  margin-right: 0.5em;
  flex-shrink: 0;
}

.buttonToolBar {
  text-align: right;
}

.screenName,
.username {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tagGroup {
  text-align: right;
}

.rejected {
  color: #bf2008 !important;
}
