.@{ns}avatar {
  border: 2px solid @avatar-background;

  &-xs {
    border-width: 1px;
  }

  &-image {
    object-fit: cover;
  }

  > .@{ns}icon {
    font-size: 1em;
  }
}
