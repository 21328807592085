.@{ns}icon {
  svg {
    width: 1em;
    height: 1em;
    transition: fill 0.3s linear;
    fill: currentColor;
    vertical-align: middle;
    margin-top: -0.125em;
    margin-left: -0.0625em;
  }
}
