.list {
  &__item {
    padding-left: 13px;
    padding-right: 13px;
  }
}

.l-flex--with-gap {
  & > * {
    &:not(:first-child) {
      margin-left: 10px;
    }
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
