.@{ns}input {
  &-group {
    display: flex;

    &-addon {
      width: auto;
    }
  }

  &-dropzone {
    background: rgba(@btn-primary-bg, 0.85);
    color: @btn-primary-color;
    font-size: @font-size-large;

    &:before {
      border-color: @btn-primary-color;
    }
  }
}

&-addon,
&-btn,
&:not(&-inside) .@{ns}input {
  display: block;
}
