.@{ns}flex-box-grid {
  &.no-wrap {
    flex-wrap: nowrap;
  }

  &.item-spacing {
    .rs-form-control-wrapper + .@{ns}flex-box-grid-item,
    .@{ns}flex-box-grid-item + .rs-form-control-wrapper,
    .@{ns}flex-box-grid-item + .@{ns}flex-box-grid-item {
      margin-left: 20px;
    }

    &.flex-row-reverse {
      .rs-form-control-wrapper + .@{ns}flex-box-grid-item,
      .@{ns}flex-box-grid-item + .rs-form-control-wrapper,
      .@{ns}flex-box-grid-item + .@{ns}flex-box-grid-item {
        margin-right: 20px;
        margin-left: 0;
      }
    }
  }

  &.flex-row-reverse {
    flex-direction: row-reverse;
  }
}
