.@{ns}sidenav {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .@{ns}dropdown .@{ns}dropdown-toggle,
  .@{ns}nav-item .@{ns}nav-item-content {
    text-align: left;
    padding: 10px 15px;
    font-size: 14px;
  }

  &-nav {
    ul {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  .@{ns}dropdown-menu
    > .@{ns}dropdown-item-active
    > .@{ns}dropdown-item-content {
    font-weight: normal;
  }
}

.@{ns}sidenav-subtle.@{ns}sidenav-collapse-in {
  .@{ns}dropdown.@{ns}dropdown-collapse {
    .@{ns}dropdown-menu.@{ns}dropdown-menu-active {
      ~ .@{ns}dropdown-toggle {
        box-shadow: inset -2px 0px 0px 0px @dropdown-link-active-color;
        color: @dropdown-link-active-color;
      }
    }
  }
}
