.@{ns}popover {
  &.no-arrow {
    .@{ns}popover-arrow {
      display: none;
    }
  }

  &-footer {
    margin-top: 20px;

    .@{ns}btn-toolbar {
      text-align: right;
    }
  }

  &.normal-font-size {
    .@{ns}popover-content {
      font-size: @font-size-base;
    }
  }
}
