.@{ns}navbar {
  line-height: 0;

  &-header {
    display: inline-flex;
    align-items: center;
  }

  &-brand {
    padding: 0 32px;
  }

  &-caption {
    padding: 0 32px 0 16px;
  }

  &-brand,
  &-caption {
    height: 56px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  &-brand {
    svg {
      width: 2em;
      height: 2em;
    }
  }

  &-inverse {
    .@{ns}avatar {
      border-color: @nav-bar-inverse-font-color;
      box-shadow: 0 1px 4px #00000044;
    }

    .@{ns}dropdown-item-panel {
      color: @dropdown-link-color;
      line-height: 0.8;
    }
  }

  .@{ns}dropdown-toggle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 10px !important;
  }
}
