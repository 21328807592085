@form-control-error-message-drop-shadow-width: 6px;

.@{ns}form {
  &-title {
    font-weight: bold;
    font-size: 1.1em;
    margin-bottom: 1em;
  }

  &-control-wrapper {
    .@{ns}error-message {
      white-space: normal;

      &-wrapper {
        width: calc(100% - 24px);
        left: @form-control-error-message-drop-shadow-width * 2;
      }
    }

    .@{ns}error-message-placement-bottom-start {
      .@{ns}error-message {
        margin-bottom: @form-control-error-message-drop-shadow-width * 2;
      }
    }
  }
}
