.listGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 0;
    border-left: 1px dashed lightgrey;
  }
}

.list {
  box-shadow: none !important;
}

.actionBarSingle {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid lightgrey;
}

.actionBar {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid lightgrey;
  justify-content: flex-end;
  align-items: flex-end;

  > * {
    margin-left: 10px;
  }

  .dropdownItem {
    min-width: 150px;
    max-width: 75vw;

    a {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.buttonToolbarRight {
  text-align: right;
}
