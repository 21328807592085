.@{ns}notification {
  font-size: @font-size-small;

  &-title {
    font-weight: bold;

    &-with-icon {
      margin-left: @notify-title-icon-size+ @notify-title-icon-margin;
      white-space: initial;
      width: auto;
      line-height: 1.5em;

      .@{ns}icon {
        position: absolute;
        left: 18px;
        top: 17px;
      }
    }
  }

  &-item-content {
    padding-right: 30px;
  }

  &-description {
    word-break: break-word;
    white-space: initial;
  }

  &-no-description &-description {
    display: none;
    opacity: 0.5;
  }
}
